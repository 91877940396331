import httpClient from './httpClient';

const END_POINT = '/consulting';

//Roles management
const getAllConsultingRoles = (params) => httpClient.get(END_POINT + '/roles/all', { params });

// Role management
const getConsultingRoles = (params) => httpClient.get(END_POINT + '/roles', { params });
const addConsultingRole = (role) => httpClient.post(END_POINT + '/roles', { role });
const updateConsultingRole = (id, role) => httpClient.put(END_POINT + '/roles/' + id, { role } );
const deleteConsultingRole = (id) => httpClient.delete(END_POINT + '/roles/' + id);

// Time management
const getConsultingTimes = (params) => httpClient.get(END_POINT + '/times', { params });
const addConsultingTime = (time) => httpClient.post(END_POINT + '/times', { time });
const updateConsultingTime = (id, time) => httpClient.put(END_POINT + '/times/' + id, { time });
const deleteConsultingTime = (id) => httpClient.delete(END_POINT + '/times/' + id);
const getConsultingTimesStats = () => httpClient.get(END_POINT + '/times/stats');

// Invoice management
const getOutstandingTimes = (params) => httpClient.get(END_POINT + '/times/outstanding', { params });
const invoiceConsultingTime = (mapping) => httpClient.post(END_POINT + '/times/outstanding', mapping );

// Timebank role management
const getConsultingTimebankRoles = (params) => httpClient.get(END_POINT + '/timebanks/roles', { params });
const addConsultingTimebankRole = (timebank_role) => httpClient.post(END_POINT + '/timebanks/roles', { timebank_role });
const updateConsultingTimebankRole = (id, timebank_role) => httpClient.put(END_POINT + '/timebanks/roles/' + id, { timebank_role } );
const deleteConsultingTimebankRole = (id) => httpClient.delete(END_POINT + '/timebanks/roles/' + id);
const getConsultingTimebankRoleLoggedTime = (id) => httpClient.get(END_POINT + '/timebanks/roles/times/' + id);

export {
    getAllConsultingRoles,
    getConsultingRoles,
    addConsultingRole,
    updateConsultingRole,
    deleteConsultingRole,

    getConsultingTimes,
    addConsultingTime,
    updateConsultingTime,
    deleteConsultingTime,
    getConsultingTimesStats,

    getOutstandingTimes,
    invoiceConsultingTime,

    getConsultingTimebankRoles,
    addConsultingTimebankRole,
    updateConsultingTimebankRole,
    deleteConsultingTimebankRole,
    getConsultingTimebankRoleLoggedTime
}

